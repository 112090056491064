<template>
  <div>
    <b-modal :title="'유저상세'" size="xl" v-model="m" ok-title="저장" cancel-title="닫기" @hide="modalHide">
      <b-row>
        <b-col cols="12" lg="9">
          <div class="hr-title"><b>{{ `기본정보` }}</b></div>
          <hr/>
          <b-row>
            <b-col class="mb-2" cols="6" lg="4">
              <small>ID</small><br/>
              <b-input plaintext v-model="item.id"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Name</small><br/>
              <b-input v-model="item.name"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Group</small><br/>
              <b-form-select v-model="item.teamId" :options="[
                    {text:'(팀을 선택해주세요)', value: '', disabled: true},
                    ...TEAMS.filter(e => !e.old).map(e => ({text: '　　　'.slice(0, e.padding) + e.text, value: e.value})),
                  ]"></b-form-select>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Email</small><br/>
              <b-input v-model="item.email"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>휴대폰 번호(숫자로만 입력해주세요)</small><br/>
              <b-input v-model="item.mobile"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>근무형태</small><br/>
              <b-form-select v-model="item.workType" :options="[
                    {text:'(근무형태를 선택해주세요)', value:'', disabled: true},
                    ...options.workType
                  ]"></b-form-select>
            </b-col>
          </b-row>
          <b-alert variant="info" :show="item_org.mobile !== item.mobile || item_org.email !== item.email">
            Email 이나 휴대폰 번호를 바꾸면 로그인 2차 인증이 불가할 수 있으니 주의해주시기 바랍니다.
          </b-alert>
          <b-row>
            <b-col class="mb-2">
              <small>이미지</small><br/>
              <b-input v-model="item.img_org"></b-input>
            </b-col>

            <b-col class="mb-2">
              <small>허용IP</small><br/>
              <v-select v-model="item.trustedIP" multiple taggable push-tags placeholder="신뢰할 수 있는 IP를 입력해주세요">
              </v-select>
            </b-col>
          </b-row>

          <b-tabs class="mt-2" v-model="tabIndex">
            <b-tab title="권한설정">
              <role-tab v-bind="{item}"></role-tab>
            </b-tab>
            <b-tab title="세부기능관리">
              <route-tab v-if="loaded[1] || tabIndex === 1" v-bind="{item}"></route-tab>
            </b-tab>
          </b-tabs>
<!--          <div class="hr-title"><b>권한설정</b></div>
          <hr/>
          <div class="mb-3">
            R: 읽기권한, W: 쓰기권한, WB: 대량(batch) 쓰기권한, X: 실행(알림톡 발송 등 취소 불가능한) 권한
          </div>
          <b-row v-for="r in Object.keys(ROLE_INFO)">
            <b-col cols="2" class="mt-1 mb-2">
              {{ ROLE_INFO[r].title }}
              <i class="fa fa-question-circle" v-b-tooltip="ROLE_INFO[r].text"></i>
            </b-col>
            <b-col cols="2" class="mb-1">
              <b-form-checkbox size="sm" v-model="item.role" :value="r" :button-variant="item.role && ~item.role.indexOf(r) ? 'info' : 'light'" button>
                {{ r }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="ROLE_GROUP[r]" class="mb-1">
              <b-form-checkbox v-for="rr in ROLE_GROUP[r].split(',')" size="sm" class="mr-2" v-model="item.role" :value="rr" :key="rr"
                               :button-variant="item.role && ~item.role.indexOf(rr) ? 'success' : 'light'" button>
                {{ rr }}
              </b-form-checkbox>
            </b-col>
          </b-row>-->

        </b-col>
        <b-col cols="12" md="3" class="border-left">
          <a :href="item.img_org || item.slackUser && item.slackUser.img" v-if="item.img" target="_blank"><img class="mw-100" :src="item.img" /></a>
          <img v-else class="mw-100" :src="'https://i.balaan.io/blank/noimg_goods_200.webp'" />

          <div class="hr-title"><b>Last Login</b></div>
          <hr/>
          <div v-if="item.last_login_dt">
            {{item.last_login_dt}} ({{item.last_login_ip}})
          </div>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="warning" @click="resetPW(item)" :disabled="busy.resetPW">
          비밀번호 초기화
          <b-spinner class="ml-1" small v-if="busy.resetPW"></b-spinner>
        </b-button>
        <b-button v-if="$R('DB_ADMIN')" variant="outline-primary" @click="openDBModal">
          DB 계정 관리
        </b-button>
        <b-button v-if="$R('ADMIN')" variant="outline-light" @click="$modal.show({title:'JSON 보기', html:'<pre>' + JSON.stringify(item, null, 2) + '</pre>'})">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="$modal.show({type:'diff', diff:item._diff})">
          수정이력
        </b-button>
        <b-button v-if="$R('USER_X')" variant="outline-danger" @click="removeUser(item)">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
        <b-button v-if="$R('USER_W')" variant="primary" @click="ok()">
          저장
        </b-button>
      </template>
    </b-modal>

    <db-modal v-if="modal.db" v-model="modal.db" v-bind="{item}"></db-modal>
  </div>
</template>

<script>
import dbModal from '@/views/user/modal/DBModal.vue'
import roleTab from '@/views/user/modal/RoleTab.vue'
import routeTab from '@/views/user/modal/RouteTab.vue'
const CHECK_COL = 'email,name,mobile,teamId,workType,img_org,role,blacklist,whitelist,trustedIP'.split(',');

export default {
  name: 'UserModal',
  title: '사용자 상세',
  props: ['TEAMS', 'teamMap', 'teams', 'item', 'item_org', 'value', 'options'],
  model: {prop: 'value', event: 'change'},
  components: {dbModal, roleTab, routeTab},
  data() {
    return {
      busy: {
        resetPW: false,
      },
      modal: {
        db: false
      },
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  async created() {
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
  computed: {
    m: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  methods: {
    async modalHide(event) {
      // console.log(event, event.trigger);

      let modifiedCols = CHECK_COL.filter(e => JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));
      if (event.trigger === 'ok') {
        if (modifiedCols.length) {
          event.preventDefault && event.preventDefault();
          let item = {id: this.item.id};
          modifiedCols.forEach(e => item[e] = this.item[e]);
          if (item.hasOwnProperty('img_org')) {
            item.img = item.img_org;
            delete item.img_org;
          }
          let j = await this.$api.postJson('/user/update', {item});
          if (j) {
            this.$emit('refresh');
            this.m = false;
          }
        }
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        if (modifiedCols.length) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
              event.preventDefault();
            }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    async resetPW(obj) {
      if (!confirm(`비밀번호가 랜덤한 문자열로 초기화되며, 해당 사용자는 로그아웃 됩니다. 진행하시겠습니까?`)) return;
      this.busy.resetPW = true;
      const j = await this.$api.postJson('/user/resetPW', {id: obj.id});
      this.busy.resetPW = false;
      if (j) {
        this.$utils.alert(`ID: ${obj.id}, 비밀번호: ${j.plain}<br/>비밀번호를 바로 변경하도록 안내해주세요`);
      }
    },
    async removeUser(obj) {
      if (!confirm(`정말로 [${obj.name}] 사용자를 삭제하시겠습니까?`)) return;
      let j = await this.$api.postJson('/user/remove', {id: obj.id});
      if (j) this.$emit('refresh');
      this.m = false;
    },
    openDBModal() {
      this.modal.db = true;
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="mb-3">
      기능의 권한 체크는 BlackList -> WhiteList -> Role 순으로 이뤄집니다
    </div>

    <b-form-checkbox-group v-model="checkList">
      <div class="grid">
        <div class="th text-center">Method</div>
        <div class="th">기능 / 경로</div>
        <div class="th text-center">개인정보</div>
        <div class="th text-center">기능권한</div>
        <div class="th text-center">현재권한</div>
        <div class="th text-center">Black</div>
        <div class="th text-center">White</div>
        <template v-for="e in list">
          <div class="td text-center">{{ e.method }}</div>
          <div class="td">
            <div class="fs-13">{{ e.name }} <i v-if="e.desc" class="fa fa-question-circle-o pointer" v-b-tooltip="e.desc"></i></div>
            <div class="fs-11 mt-n1">{{ e.route }}</div>
          </div>
          <div class="td text-center">{{ e.privacy ? 'Y' : '' }}</div>
          <div class="td text-center fs-13">{{ e.role }}</div>
          <div class="td text-center">{{ e.hasRole ? 'O' : '' }}</div>
          <div class="td text-center"><b-form-checkbox :value="`B-${e.method} ${e.route}`"></b-form-checkbox></div>
          <div class="td text-center"><b-form-checkbox :value="`W-${e.method} ${e.route}`"></b-form-checkbox></div>
        </template>
      </div>
    </b-form-checkbox-group>
  </div>
</template>

<script>
export default {
  name: 'RouteTab',
  title: '세부기능관리',
  props: ['item'],
  data() {
    return {
      ROLE_GROUP: this.$C.ROLE_GROUP,
      ROLE_INFO: this.$C.ROLE_INFO,
      list: [],
      listMap: {},
      roleMap: {},
      checkList: []
    }
  },
  async created() {
  },
  async mounted() {
    this.$utils.expandRole(this.item.role).forEach(e => this.roleMap[e] = true);
    this.roleMap[''] = true; // 권한 필요 없음
    this.roleMap['LOGIN'] = true; // 기본 로그인
    const j = await this.$api.getJson('/user/routes/list');
    if (j) {
      this.list = j.list.filter(e => e.open);
      this.list.forEach(e => {
        if (e.role === '""') e.role = 'LOGIN';
        if (typeof e.role === 'string') {
          if (this.roleMap[e.role]) e.hasRole = true;
        } else {
          if (e.role.some && e.role.some(r => this.roleMap[r])) e.hasRole = true;
        }
      });
      this.listMap = this.$utils.arr2map(this.list, 'method,route', true, {keySeperator: ' '});
      this.checkList = (this.item.blacklist.filter(e => this.listMap[e]) || []).map(e => `B-${e}`)
        .concat((this.item.whitelist.filter(e => this.listMap[e]) || []).map(e => `W-${e}`));
    }
  },
  watch: {
    checkList: {
      deep: true,
      handler(v) {
        this.item.blacklist = v.filter(e => e.startsWith('B-')).map(e => e.replace(/^B-/, ''));
        this.item.whitelist = v.filter(e => e.startsWith('W-')).map(e => e.replace(/^W-/, ''));
      },
    },
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 60px 2fr 68px 1fr repeat(3, 68px);
  margin: 10px 0;
}
.grid .th {
  padding: 5px;
  background-color: #eee;
}
.grid .td {
  padding: 5px;
}
</style>

<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" v-model="form.search" @keypress.enter="list" v-focus></b-form-input>
      </b-input-group>

      <b-row class="mb-2">
        <b-col>
          <small>정렬방식</small>
          <b-form-radio-group v-model="form.sort" :options="options.sort"></b-form-radio-group>
        </b-col>
        <b-col xl="3" lg="6">
          <small>근무형태</small><br/>
          <b-button class="mr-3" size="sm" variant="warning" @click="toggleWorkTypes">전체</b-button>
          <b-form-checkbox-group name="statusMulti" v-model="form.workTypes">
            <b-form-checkbox v-for="e in options.workType" :key="e.value" :value="e.value">
              {{ e.text }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
        <b-col xl="3" lg="6">
          <small>승인여부</small>
          <b-form-radio-group v-model="form.approved" :options="options.approved">
          </b-form-radio-group>
        </b-col>
        <b-col xl="3" lg="6">
          <small>마지막 로그인 시각</small><br/>
          <b-form inline>
            <b-form-input class="text-center w-160px" v-model="form.login_dt_from" placeholder="2020-04-01 00:00:00"></b-form-input>
            ~
            <b-form-input class="text-center w-160px" v-model="form.login_dt_to" placeholder="2020-04-01 00:00:00"></b-form-input>
          </b-form>
        </b-col>
      </b-row>

      <b-collapse id="collapse">
        <b-button class="mr-3 mb-2" size="sm" variant="warning" @click="toggleAll">전체</b-button>
        <b-form-checkbox-group v-model="form.teams">
          <b-row class="">
            <b-col xl="2" lg="3" md="4" cols="6">
              <b-button size="sm" variant="outline-warning" class="pull-right" @click="toggleBalaan">Toggle</b-button>
              <b-form-checkbox :value="'eB8qOVqxzK'">발란</b-form-checkbox><!-- 발란 -->
            </b-col>
          </b-row>
          <b-row class="">
            <!-- 발란 직속, 하위조직 있음 -->
            <b-col v-for="t in teamMap['eB8qOVqxzK'].subtree.filter(e => e.subtree)" :key="t.id" xl="3" md="4" sm="6" cols="12" class="mt-2 clearfix">
              <b-button size="sm" variant="outline-warning" class="pull-right" @click="toggleSubtree(t)">Toggle</b-button>
              <b-form-checkbox :value="t.id">{{t.text}}</b-form-checkbox><br/>
              <div v-for="e in (t.subtree || [])" :key="e.id">
                <b-form-checkbox :value="e.id" class="ml-3">
                  {{ e.text }}
                </b-form-checkbox>
              </div>
            </b-col>
            <!-- 발란 직속, 하위조직 없음 -->
            <b-col xl="3" md="4" sm="6" cols="12" class="mt-2 clearfix">
              <div v-for="t in teamMap['eB8qOVqxzK'].subtree.filter(e => !e.subtree)" :key="t.id">
                <b-form-checkbox :value="t.id" class="">
                  {{ t.text }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>

          <hr />

          <!-- not 발란(협력사 등) -->
          <b-row class="">
            <b-col v-for="t in teams.filter(e => !e.balaan && !e.old && !e.parentId)" :key="t.id" xl="3" md="4" sm="6" cols="12" class="mt-2 clearfix">
              <b-button size="sm" variant="outline-warning" class="pull-right" @click="toggleSubtree(t)">Toggle</b-button>
              <b-form-checkbox :value="t.id">{{t.text}}</b-form-checkbox><br/>
              <div v-for="e in (t.subtree || [])" :key="e.id">
                <b-form-checkbox :value="e.id" class="ml-3">
                  {{ e.text }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>

          <!--          <hr />
                    <b-form-checkbox :value="null">팀 없음</b-form-checkbox>-->

          <hr />

          <b-row>
            <b-col>
              <small>DB 이력</small>
              <b-form-radio-group class="col-form-label" v-model="form.dbUser" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
            </b-col>
<!--            <b-col>
              <small>개인정보 마스킹 테스트</small>
              <b-form-radio-group class="col-form-label" v-model="form.masked" :options="[
                {text: '마스킹 없음', value: false},
                {text: '마스킹 있음', value: true}
              ]"></b-form-radio-group>
            </b-col>-->
          </b-row>

        </b-form-checkbox-group>
      </b-collapse>

      <div class="mt-3">
        <b-button class="mr-1" variant="primary" @click="list">검색</b-button>
        <b-button class="" variant="outline-success" v-b-toggle.collapse>상세검색조건</b-button>
      </div>
    </b-card>

    <div class="d-flex align-items-center">
      <!--<b-button class="m-1" variant="light" @click="list">새로고침</b-button>-->
      <div>
        <b-button class="m-1" variant="success" @click="randomLunchModal()">랜덤런치 조편성</b-button>
        <b-button class="m-1" variant="outline-secondary" @click="refreshSlackUser()" :disabled="busy.slack">
          Slack User Refresh
          <b-spinner class="ml-1" small v-if="busy.slack"></b-spinner>
        </b-button>
      </div>
      <div class="ml-auto">
        <xlsx v-if="$R('ADMIN')" :types="['xlsx']" :preFunc="preDown" :data="items.list" :labels="xlsx.labels" :keys="xlsx.keys" name="Users"></xlsx>
        <!--<b-button class="m-1" variant="success" @click="down('xlsx')">XLSX</b-button>
        <b-button class="m-1" variant="success" @click="down('txt')">Text</b-button>-->
        <b-button-group class="m-1">
          <!-- 리스트방식 -->
          <b-button :variant="listType === 'list' ? 'dark' : 'light'" @click="listType = 'list'"><i class="fa fa-list"></i></b-button>
          <!-- 이미지방식 -->
          <b-button :variant="listType === 'img' ? 'dark' : 'light'" @click="listTypeImg"><i class="fa fa-picture-o"></i></b-button>
        </b-button-group>
      </div>
    </div>

    <b-card v-if="listType === 'img'">
      <div v-for="g in teamIds">
        <h5>{{teamMap[g.teamId].text}}</h5>
        <div class="d-flex align-items-center flex-wrap">
          <div v-for="u in g.users" class="flex-grow-0 m-1 w-150px">
            <div class="position-relative">
              <!-- 상단 정보 -->
              <div class="position-absolute" style="left:0;line-height:15px">
                <b-badge variant="primary">{{u.id}}</b-badge><br/>
              </div>
  <!--            <img :src="u.img || 'https://i.balaan.io/blank/noimg_review_200.webp'" class="w-100" @click="btnAction({item: u}, 'show_modal')"/>-->
              <img :src="u.img || 'https://i.balaan.io/blank/noimg_goods_200.webp'" class="center-cropped" @click="btnAction({item: u}, 'show_modal')"/>
              <!-- 하단 정보 -->
              <div class="position-absolute text-right" style="bottom:0;right:0">
                <b-badge variant="light">{{u.name}}</b-badge><br/>
              </div>
            </div>
            <div class="text-center mt-2">
              <b-btn size="sm" variant="success" class="mr-1" @click="btnAction({item: u}, 'show_modal')">상세</b-btn>
              <b-btn size="sm" variant="danger" class="" @click="btnAction({item: u}, 'remove')">삭제</b-btn>
            </div>
          </div>
        </div>
        <hr/>
      </div>
    </b-card>


    <c-table v-if="listType === 'list'" :table-data="items.list" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" @get-more="list(true)"
             :getMoreBusy="busy.listmore" :hasMore="hasMore.list" :caption="items.list.length + ' 명'" @btn-clicked="btnAction"></c-table>

<!--    <b-modal :title="T('유저상세')" size="xl" v-model="modal.detail" ok-title="저장" cancel-title="닫기" @hide="modalHide">
      <b-row>
        <b-col cols="12" lg="9">
          <div class="hr-title"><b>{{ T(`기본정보`) }}</b></div>
          <hr/>
          <b-row>
            <b-col class="mb-2" cols="6" lg="4">
              <small>ID</small><br/>
              <b-input plaintext v-model="item.id"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Name</small><br/>
              <b-input v-model="item.name"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Group</small><br/>
              <b-form-select v-model="item.teamId" :options="[
                  {text:'(팀을 선택해주세요)', value: '', disabled: true},
                  ...TEAMS.filter(e => !e.old).map(e => ({text: '　　　'.slice(0, e.padding) + e.text, value: e.value})),
                ]"></b-form-select>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>Email</small><br/>
              <b-input v-model="item.email"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>휴대폰 번호(숫자로만 입력해주세요)</small><br/>
              <b-input v-model="item.mobile"></b-input>
            </b-col>
            <b-col class="mb-2" cols="6" lg="4">
              <small>근무형태</small><br/>
              <b-form-select v-model="item.workType" :options="[
                  {text:'(근무형태를 선택해주세요)', value:'', disabled: true},
                  ...options.workType
                ]"></b-form-select>
            </b-col>
          </b-row>
          <b-alert variant="info" :show="item_org.mobile !== item.mobile || item_org.email !== item.email">
            Email 이나 휴대폰 번호를 바꾸면 로그인 2차 인증이 불가할 수 있으니 주의해주시기 바랍니다.
          </b-alert>
          <b-row>
            <b-col class="mb-2">
              <small>이미지</small><br/>
              <b-input v-model="item.img_org"></b-input>
            </b-col>
          </b-row>

          <div class="hr-title"><b>권한설정</b></div>
          <hr/>
          <div class="mb-3">
            R: 읽기권한, W: 쓰기권한, WB: 대량(batch) 쓰기권한, X: 실행(알림톡 발송 등 취소 불가능한) 권한
          </div>
          <b-row v-for="r in Object.keys(ROLE_INFO)">
            <b-col cols="2" class="mt-1 mb-2">
              {{ ROLE_INFO[r].title }}
              <i class="fa fa-question-circle" v-b-tooltip="ROLE_INFO[r].text"></i>
            </b-col>
            <b-col cols="2" class="mb-1">
              <b-form-checkbox size="sm" v-model="item.role" :value="r" :button-variant="item.role && ~item.role.indexOf(r) ? 'info' : 'light'" button>
                {{ r }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="ROLE_GROUP[r]" class="mb-1">
              <b-form-checkbox v-for="rr in ROLE_GROUP[r].split(',')" size="sm" class="mr-2" v-model="item.role" :value="rr" :key="rr"
                               :button-variant="item.role && ~item.role.indexOf(rr) ? 'success' : 'light'" button>
                {{ rr }}
              </b-form-checkbox>
            </b-col>
          </b-row>

        </b-col>
        <b-col cols="12" md="3" class="border-left">
          <a :href="item.img_org || item.slackUser && item.slackUser.img" v-if="item.img" target="_blank"><img class="mw-100" :src="item.img" /></a>
          <img v-else class="mw-100" :src="'https://i.balaan.io/blank/noimg_goods_200.webp'" />

          <div class="hr-title"><b>Last Login</b></div>
          <hr/>
          <div v-if="item.last_login_dt">
            {{item.last_login_dt}} ({{item.last_login_ip}})
          </div>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="warning" @click="resetPW(item)" :disabled="busy.resetPW">
          비밀번호 초기화
          <b-spinner class="ml-1" small v-if="busy.resetPW"></b-spinner>
        </b-button>
        <b-button v-if="$R('DB_ADMIN')" variant="outline-primary" @click="openDBModal">
          DB 계정 관리
        </b-button>
        <b-button v-if="$R('ADMIN')" variant="outline-light" @click="$modal.show({title:'JSON 보기', html:'<pre>' + JSON.stringify(item, null, 2) + '</pre>'})">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="$modal.show({type:'diff', diff:item._diff})">
          수정이력
        </b-button>
        <b-button v-if="$R('USER_X')" variant="outline-danger" @click="removeUser(item)">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
        <b-button v-if="$R('USER_W')" variant="primary" @click="ok()">
          저장
        </b-button>
      </template>
    </b-modal>-->

    <user-modal v-if="modal.detail" v-model="modal.detail" v-bind="{TEAMS, teamMap, teams, item, item_org, options}" @refresh="list()"></user-modal>

    <b-modal v-model="modal.random" title="랜덤런치 조편성" @ok="randomLunch" ok-title="조편성 확인">
      <h5>참여대상의 이름을 입력해주세요</h5>
      <b-textarea v-model="randomLunchText" :placeholder="'ex)\n김발란\n최발란\n...'" rows="20"></b-textarea>
    </b-modal>

    <b-modal v-model="modal.randomResult" title="랜덤런치 조편성 결과" @ok="randomLunchSend" ok-title="조편성 전송">
      총 조편성 : {{randomLunchResult.groupCnt}} 개 조<br/>
      다시 만남 점수<i class="fa fa-question-circle ml-1" v-b-tooltip="'이전 랜덤런치때 만났던 사람이 또 같은조가 된다면 점수가 올라갑니다. 랜덤하게 잘 섞일수록 점수가 낮습니다'"></i> :
      {{ randomLunchResult.cost }} 점<br/>
      <hr/>
      <div v-for="(g, idx) in randomLunchResult.groups" :key="idx">
        {{idx + 1}} 조 : {{g.join(', ')}}
      </div>
      <hr/>
      <div>슬랙으로 전송하시려면 하단의 조편성 전송 버튼을 눌러주세요</div>
    </b-modal>
  </div>
</template>

<style scoped>
  .center-cropped {
    object-fit: cover;
    object-position: center; /* Center the image within the element */
    height: 150px;
    width: 150px;
  }
</style>

<script>
import {getT} from '@/shared/i18n'
import xlsx from '@/views/modules/Xlsx.vue'
import userModal from '@/views/user/modal/UserModal.vue'

const T = getT('en-US', 'Users');

export default {
  name: 'Users',
  title: '사용자관리',
  components: {xlsx, userModal},
  data() {
    return {
      T,
      TEAMS: [], // this.$C.TEAMS
      teamMap: {}, // this.$utils.arr2map(this.$C.TEAMS, 'value'),
      teams: [], // this.$C.TEAMS.filter(e => !e.disabled),
      form: {
        search: '',
        approved: 'ALL',
        teams: [],
        workTypes: [],
        login_dt_from: '',
        login_dt_to: '',
        dbUser: 'ALL',
        masked: false,
        sort: '_cdt:-1',
        limit: 1000,
        skip: 0,
      },
      listType: 'list',
      // groups: [],
      teamIds: [],
      item: {},
      item_org: {},
      fields: [
        {key: '_img40', label: '이미지', sortable: true, class: 'w-100px'},
        {key: 'html2', label: 'ID<br/>Name', sortable: true},
        {key: 'html5', label: '소속', sortable: true, class: 'w-170px'},
        {key: 'html6', label: '근무형태', sortable: true, class: 'text-center w-100px'},
        {key: 'html4', label: 'Email<br/>Slack User', sortable: true},
        {key: 'html3', label: 'Join<br/>Last Login', sortable: true, class: 'text-center w-200px'},
        {key: 'html7', label: 'DB 계정', class: 'text-center w-100px'},
        {key: 'html1', label: 'Roles'},
        {
          key: '_actions2',
          label: '승인',
          buttons: [
            {label: '승인', variant: 'success', if: e => !e.approved, else: () => '승인됨', event: 'approve'}
          ],
          class: 'text-center w-100px'
        },
        {
          key: '_actions',
          label: 'Actions',
          buttons: [
            {label: '상세', variant: 'success', event: 'show_modal'},
            {label: '삭제', variant: 'danger', event: 'remove'}
          ],
          class: 'text-center w-125px'
        },
      ],
      perPage: 20,
      lastBody: {list: {}},
      items: {list: []},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      busy: {
        list: false,
        listmore: false,
        slack: false,
      },
      modal: {
        detail: false,
        random: false,
        randomResult: false
      },
      xlsx: {
        keys: [],
        labels: [],
      },

      randomLunchText: '',
      randomLunchResult: {},
      options: {
        sort: [
          {text: '가입일시', value: '_cdt:-1'},
          {text: '마지막로그인', value: 'last_login_dt:-1'},
          {text: 'ID', value: 'id:1'},
          {text: '이름', value: 'name:1'},
          {text: 'Email', value: 'email:1'},
        ],
        workType: [
          {text: '발라니어', value: 'balaaneer'},
          {text: '협력사', value: 'partner'},
          {text: '파견직', value: 'dispatch'},
          {text: '계약직/인턴', value: 'contract'},
          {text: '기타', value: 'etc'},
          {text: '없음', value: null},
        ],
        approved: [
          {text: '전체', value: 'ALL'},
          {text: '승인됨', value: 'Y'},
          {text: '미승인', value: 'N'},
        ]
      },
      optionMap: {}
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');

    this.makeTeamTree();
    this.form.teams = this.teams.map(e => e.value);
    this.form.workTypes = this.options.workType.map(e => e.value);
    this.optionMap.workType = this.$utils.arr2map(this.options.workType, 'value', 'text');

    this.list();
  },
  watch: {
    perPage: function () {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    makeTeamTree() {
      const TEAMS = this.TEAMS = this.$utils.clone(this.$C.TEAMS);
      this.teamMap = this.$utils.arr2map(TEAMS, 'value');
      this.teams = TEAMS.filter(e => !e.disabled);
      TEAMS.forEach(e => {
        if (e.parentId) {
          const parent = this.teamMap[e.parentId];
          (parent.subtree = parent.subtree || []).push(e);
        }
      });
      TEAMS.forEach(e => {
        let me = e;
        let padding = 0;
        while (me.parentId && this.teamMap[me.parentId]) {
          padding++;
          me = this.teamMap[me.parentId];
        }
        e.padding = padding;
      });
    },
    toggleAll() {
      this.form.teams = this.form.teams.length === this.teams.length ? [] : this.teams.map(e => e.value);
    },
    toggleBalaan() {
      const balaanIds = this.teams.filter(e => e.balaan).map(e => e.id);
      if (balaanIds.some(id => this.form.teams.includes(id))) {
        this.form.teams = this.form.teams.filter(id => !balaanIds.includes(id));
      } else {
        this.form.teams = this.form.teams.concat(balaanIds).set();
      }
    },
    toggleSubtree(t) {
      // subTree 전체를 on / off 한다.
      const ids = (t.subtree || []).map(e => e.id).concat(t.id);

      if (ids.some(id => this.form.teams.includes(id))) {
        this.form.teams = this.form.teams.filter(id => !ids.includes(id));
      } else {
        this.form.teams = this.form.teams.concat(ids).set();
      }
    },
    toggleWorkTypes() {
      this.form.workTypes = this.form.workTypes.length === this.options.workType.length ? [] : this.options.workType.map(e => e.value);
    },
    async list(more) {
      const teams = this.form.teams.length === this.teams.length ? [] : this.form.teams;
      const workTypes = this.form.workTypes.length === this.options.workType.length ? [] : this.form.workTypes;
      const j = await this.$api.postTable(this, '/user/list', {...this.form, teams, workTypes}, {more, fnAssign: this.assignTableData});

      // 이미지보기 그룹화를 위한 후처리
      const teamMul = this.$utils.arr2multi(j.list, 'teamId');
      const teamIds = Object.entries(teamMul).map(([teamId, users]) => ({
        teamId,
        users,
        idx: this.TEAMS.findIndex(e => e.id === teamId),
      }));
      teamIds.sort((a, b) => a.idx - b.idx);
      this.teamIds = teamIds;
    },
    assignTableData(e) {
      const team = this.teamMap[e.teamId] || {};
      const parent = this.teamMap[team.parentId];
      e.html1 = e.role.map(r => `<span class="badge badge-primary">${r}</span>`).join(' ');
      e.html2 = `${e.id}<br/>${e.name}`;
      e.html3 = `${e._cdt}<br/>${e.last_login_dt || ''}`;
      e.html4 = `${e.email_mask || e.email}<br/>${e.slackUsers ?
        `<span class="badge badge-light">Slack User${e.slackUsers.length > 1 ? `(${e.slackUsers.length})` : ''}</span> ${e.slackUsers.map(e => e.name).join(', ')}` :
        ''}`;
      e.html5 = (parent && parent.text !== '발란' ? `<span class="fs-11">${parent.text}</span><br/>` : '')
        + `${team.text || '-'}`;
      e.html6 = this.optionMap.workType[e.workType] || '-';
      e.html7 = e.dbUser ?
        Object.keys(e.dbUser.prod || {}).map(e => `<span class="badge alert-primary">${e}</span>`)
          .concat(Object.keys(e.dbUser.dev || {}).map(e => `<span class="badge alert-warning">${e}-dev</span>`)).join(' ')
        : '-';
      e.img_org = e.img;
      e.img = e.img || e.slackUser && e.slackUser.img_192;
    },
    listTypeImg() {
      this.listType = 'img';
      if (this.hasMore.list) {
        this.form.limit = 1000;
        this.list();
      }
    },
    async btnAction(row, event) {
      let obj = row.item, j;
      if (event === 'approve') {
        if (!confirm(`정말로 [${obj.name}] 사용자를 승인하시겠습니까?`)) return;
        j = await this.$api.postJson('/user/approve', {id: obj.id});
      } else if (event === 'remove') {
        if (!confirm(`정말로 [${obj.name}] 사용자를 삭제하시겠습니까?`)) return;
        j = await this.$api.postJson('/user/remove', {id: obj.id});
      } else if (event === 'show_modal') {
        this.showModal(row);
      }
      if (j) this.list();
    },
    showModal(row) {
      this.item_org = row.item;
      this.item = this.$utils.clone(row.item);
      this.modal.detail = true;
    },
    async removeUser(obj) {
      if (!confirm(`정말로 [${obj.name}] 사용자를 삭제하시겠습니까?`)) return;
      let j = await this.$api.postJson('/user/remove', {id: obj.id});
      if (j) this.list();
    },
    randomLunchModal() {
      this.randomLunchText = '';
      this.modal.random = true;
    },
    async randomLunch() {
      const users = this.randomLunchText.trim().split(/\s+/g);
      const j = await this.$api.postJson('/user/randomLunch', {users});
      if (j) {
        this.randomLunchResult = j.doc;
        this.modal.randomResult = true;
      }
    },
    async randomLunchSend() {
      const j = await this.$api.postJson('/user/randomLunchSend', this.randomLunchResult);
      if (j) {
        alert('Slack 으로 전송되었습니다. 결과를 확인해주세요.');
      }
    },
    async refreshSlackUser() {
      this.busy.slack = true;
      await this.$api.getJson('/user/refreshSlackUser');
      this.busy.slack = false;
    },

    preDown() {
      this.xlsx.keys = 'id,name,email,mobile,teamId,workType,approved,pw_mod_dt,last_login_dt,last_login_ip'.split(',');
      this.xlsx.labels = 'ID,Name,Email,Mobile,TeamId,근무형태,승인여부,PW변경일시,로그인일시,로그인IP'.split(',');

      return true;
    },
  }
}
</script>

<style scoped>
div.hr-title {
  margin-top: 8px;
  margin-bottom: -10px;
}
</style>

<template>
  <div>
    <b-modal v-model="m" title="DB 계정 관리" size="lg" ok-only ok-title="닫기">
      <h5>계정 생성</h5>
      <b-alert show variant="danger">
        DB계정 생성은 각 환경 별 허브 어드민 페이지에서 생성 바랍니다. (현재 환경: {{ NODE_ENV }})
      </b-alert>
      <b-row class="mb-2">
        <b-col>
          <small>ID</small>
          <b-form-input class="" v-model="dbForm.id" readonly></b-form-input>
        </b-col>
        <b-col>
          <small>Name</small><br/>
          <b-form-input class="" v-model="dbForm.name"></b-form-input>
          <small v-if="slackUserAlert" class="text-danger">{{ slackUserAlert }}</small>
          <small v-else class="text-muted">해당 이름의 Slack User 에게 DB 안내 메시지가 전송됩니다</small><br/>
        </b-col>
      </b-row>

      <b-checkbox-group v-model="dbForm.dbs">
        <b-row>
          <b-col>
            <h6>DB 목록</h6>
            <div v-for="db in dbPreset" :key="db.value">
              <b-row>
                <b-col>
                  <b-checkbox
                    :value="db.value"
                    :disabled="!!(item.dbUser.dbs && item.dbUser.dbs[db.value])"
                    >
                    {{db.text}}
                  </b-checkbox>
                </b-col>
                <b-col md="6" cols="12">
                  <b-form-radio-group
                    v-model="dbForm.dbProps[db.value]"
                    :disabled="!!(item.dbUser.dbs && item.dbUser.dbs[db.value]) || !dbForm.dbs.includes(db.value)"
                    >
                    <b-radio value="read">읽기</b-radio>
                    <b-radio value="dev">개발</b-radio>
                    <b-radio value="admin">어드민</b-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-checkbox-group>

      <div class="mt-2">
        <b-button variant="success" @click="createDB()" :disabled="busy.create">
          생성
          <b-spinner class="ml-1" small v-if="busy.create"></b-spinner>
        </b-button>
      </div>

      <hr/>

      <h5>현재 계정정보</h5>
      <div class="mb-2" v-if="item.dbUser && item.dbUser.id">
        <small>ID</small>
        <b-form-input class="font-weight-bold" :value="item.dbUser.id" plaintext></b-form-input>
        <h6>DB 목록</h6>
        <b-row class="mt-2">
          <b-col>
            <b-row class="pb-1" v-for="db in dbPreset.filter(e => item.dbUser.dbs && item.dbUser.dbs[e.value])" :key="db.value">
              <b-col md="4" cols="12">
                {{db.text}}
              </b-col>
              <b-col md="4" cols="12">
                {{ { read: '읽기', dev: '개발', admin: '어드민' }[item.dbUser.dbs[db.value].role] }} 계정
              </b-col>
              <b-col md="4" cols="12">
                <b-btn variant="danger" size="sm" @click="removeDB(db.value)" :disabled="busy.remove">
                  삭제
                  <b-spinner class="ml-1" small v-if="busy.remove"></b-spinner>
                </b-btn>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="mt-2">
          <b-button class="mr-1" variant="warning" @click="resetPW()" :disabled="busy.reset">
            PW Reset
            <b-spinner class="ml-1" small v-if="busy.reset"></b-spinner>
          </b-button>
          <b-button class="mr-1" variant="danger" @click="removeDBAll()" :disabled="busy.remove">
            전체 삭제
            <b-spinner class="ml-1" small v-if="busy.remove"></b-spinner>
          </b-button>
        </div>
      </div>
      <div class="p-2 text-center" v-else>
        DB 계정이 없습니다
      </div>
    </b-modal>

    <b-modal v-model="modal.result" title="DB 계정 관리" ok-only>
      <h5>대상의 Slack 계정을 특정할 수 없습니다.<br/>아래의 내용을 직접 Slack 으로 보내주세요</h5>
      <b-textarea v-model="dbResultText" rows="20"></b-textarea>
      <b-btn class="mt-2" variant="success" @click="$utils.copyAlert(dbResultText)">복사하기</b-btn>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "DBModal",
  title: 'DB 계정 관리',
  props: ['item', 'value'],
  model: {prop: 'value', event: 'change'},
  data() {
    return {
      dbForm: {
        id: '',
        name: '',
        dbs: [],
        dbProps: {}
      },
      slackUserAlert: '',
      dbResultText: '',
      dbPreset: [
        {text: 'Maria Godo (발란몰)', value: 'godo'},
        {text: 'Maria Stat', value: 'stat'},
        {text: 'Mongo Hub', value: 'hub'},
        {text: 'Mongo Second', value: 'second'},
        {text: 'Mongo Stat', value: 'stat-mongo'},
        {text: 'Mongo Src', value: 'src'},
        {text: 'Mongo Log', value: 'log'},
      ],
      modal: {result: false},
      busy: {create: false, reset: false, remove: false}
    }
  },
  computed: {
    m: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  async created() {
    this.item.dbUser = this.item.dbUser || {};
  },
  async mounted() {
    // id 는 발란메일 ID, 발란메일이 아니면 hub id 로 지정한다.
    this.dbForm.id = this.item.email.includes('@balaan.co.kr') ? this.item.email.replace(/@balaan.co.kr/, '').trim() : this.item.id;
    this.dbForm.id = this.dbForm.id.replace(/[^-\w]/g, '').trim();
    this.dbForm.name = this.item.slackUsers && this.item.slackUsers.length === 1 ? this.item.slackUsers[0].name : '';
    this.dbForm.name = this.dbForm.name.replace(/\(.*/, '').trim();
    if (this.item.slackUsers && this.item.slackUsers.length > 1) {
      this.slackUserAlert = `동일명의 Slack User 가 ${this.item.slackUsers.length} 명입니다.`;
    }
    this.refreshDBInfo();
  },
  methods: {
    async refreshDBInfo() {
      const j = await this.$api.getJson('/user/db/info?hubId=' + encodeURIComponent(this.item.id));
      if (j) {
        this.item.dbUser = j.dbUser || {};
        if (j.dbUser && j.dbUser.id) {
          this.dbForm.id = j.dbUser.id;
        }
        this.$forceUpdate();
      }
    },
    async createDB() {
      for (const db of this.dbForm.dbs) {
        if (!this.dbForm.dbProps[db]) return alert('읽기계정, 개발계정, 어드민계정 여부를 선택해주세요');
      }
      this.busy.create = true;
      const j = await this.$api.postJson('/user/db/create', {hubId: this.item.id, ...this.dbForm});
      this.busy.create = false;
      if (j) {
        if (!j.slackSended) {
          this.dbResultText = j.text;
          this.modal.result = true;
        } else {
          this.$utils.alert('해당 사용자에게 Slack 으로 DB 계정 정보가 전송되었습니다.');
        }
        this.refreshDBInfo();
        this.dbForm.dbs = [];
        this.dbForm.dbProps = {};
      }
    },
    async resetPW() {
      this.busy.reset = true;
      const j = await this.$api.postJson('/user/db/resetPW', {hubId: this.item.id, name: this.dbForm.name});
      this.busy.reset = false;
      if (j) {
        if (!j.slackSended) {
          this.dbResultText = j.text;
          this.modal.result = true;
        } else {
          this.$utils.alert(`DB 계정 암호가 초기화 되었습니다. 해당 사용자에게 Slack 으로 DB 계정 정보가 전송되었습니다.`);
        }
        this.refreshDBInfo();
      }
    },
    async removeDB(db) {
      this.busy.remove = true;
      const j = await this.$api.postJson('/user/db/remove', {hubId: this.item.id, dbs: [db]});
      this.busy.remove = false;
      if (j && j.ok === 1) {
        this.$utils.alert(`DB 계정이 삭제되었습니다: ${j.dbs.join(', ')}`);
        this.refreshDBInfo();
      } else {
        this.$utils.alert(`DB 계정 삭제에 실패하였습니다: ${JSON.stringify(j)}`);
      }
    },
    async removeDBAll() {
      this.busy.remove = true;
      const j = await this.$api.postJson('/user/db/remove', {
        hubId: this.item.id,
        dbs: this.dbPreset.map(e => e.value)
      });
      this.busy.remove = false;
      if (j) {
        this.$utils.alert(`DB 계정이 삭제되었습니다: ${j.dbs.join(', ')}`);
        this.refreshDBInfo();
      }
    }
  }
}
</script>

<style scoped>

</style>


/**
 * 문장 : {
 *   'en-US': {
 *     그룹 혹은 파일 : '번역'
 *   },
 *   'ko-KR': {
 *     그룹 혹은 파일 : '번역'
 *   }
 * }
 *
 */
const TRANS = {
  '<span class="badge badge-primary">쇼핑몰</span><br/>주문코드<br/>일련번호': {
    'en-US': {
      DeliveryBoard: '<span class="badge badge-primary">Mall</span><br/>OrderNo<br/>SubOrderNo'
    }
  },
  '그룹을 선택해주세요': {
    'en-US': {
      User: 'Select Group'
    }
  },
  // '${e.goodsnm}<br/><span class="badge badge-secondary">${e.size}</span> ${e.qty} 개<br/>': { // 변수가 있는 스트링은 아래처럼 바뀐다
  '${}<br/><span class="badge badge-secondary">${}</span> ${} 개<br/>': {
    _org: '${e.goodsnm}<br/><span class="badge badge-secondary">${e.size}</span> ${e.qty} 개<br/>', // 참고용
    'en-US': {
      ApiOrder: '${}<br/><span class="badge badge-secondary">${}</span> qty: ${}<br/>'
    }
  },
  '유저상세': {
    'en-US': {
      User: 'User Detail'
    }
  },
  '기본정보': {
    'en-US': {
      User: 'Base Info'
    }
  },
};

module.exports = {
  TRANS
}

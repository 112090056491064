/**
 * TODO
 * str 수집하기
 * Vue 태그 내의 값을 번역하기
 * 시트와 연동
 * user 에 언어선택
 * 서버사이드 연동
 */
import {TRANS} from './trans';

export function getT(locale, group, translateValues = false) {
  function T(str, ...values) {
    /**
     * tag function 을 이용하여 번역스트링을 제공한다.
     *
     * username = ssh, msgCnt = 3 일 경우
     * T`welcome ${username}\n, you have ${msgCnt} messages` 를 실행하면
     * str = ["welcome ", "↵, you have ", " messages"]
     * str.raw = ["welcome ", "\n, you have ", " messages"]
     * values = ["ssh", 3]
     * 이렇게 들어온다. 각 토큰, 들어온 값 등을 번역한다.
     *
     * str이 1개라면 T로 들어오는 값을 수집한다. 특수기호가 없는 것을 가정한다.
     *
     * str은 T`aaa` 로 사용할 경우 ['aaa'] 로 들어오지만, Vue element 내에서 사용할 경우 vue-template-es2015-compiler 에서 컴파일 에러가 발생한다.
     * 따라서 T('aaa') 형태도 허용하도록 한다.
     */
    let arr = str, rawStr;
    if (typeof str === 'string') { // T('aaa') 형태로 들어오는 경우
      arr = [str];
      rawStr = [str];
    } else {
      rawStr = str.raw;
    }
    let tr = TRANS[rawStr.join('${}')];
    if (translateValues) {
      values = values.map(e=>T([e]));
    }
    if (!tr || !tr[locale]) { // 해당 문장이나 언어가 없다면 번역대상으로 지정하고 위치와 함께 기록한다.
      let stack = new Error().stack.split('\n')[2];
      // console.log(arr.map((e,i)=>`${e}${i<values.length?values[i]:''}`).join(''), group, stack.match(/\w+\.vue/)[0], stack.match(/<anonymous>:(\d+:\d+)/)[1]);
      return arr.map((e,i)=>`${e}${i<values.length?values[i]:''}`).join('');
    }
    let sen = tr[locale][group] ? tr[locale][group] : Object.values(tr[locale])[0]; // 해당 그룹이 없다면 순서상 첫 번째 그룹의 번역을 선택한다.
    return sen.split('${}').map((e,i)=>`${e}${i<values.length?values[i]:''}`).join('');
  }
  return T;
}

/**
 * 문장 : {
 *   'en-US': {
 *     그룹 혹은 파일 : '번역'
 *   },
 *   'ko-KR': {
 *     그룹 혹은 파일 : '번역'
 *   }
 * }
 *
 */


/**
 * node 와의 호환성을 위해 export function 을 쓰지 않고 modules.export 를 쓸 경우
 * 황당하게도 Object.values, 'str'.split 을 사용하면 vue router에서
 * vue-router.esm.js?8c4f:16 [vue-router] Failed to resolve async component default: TypeError: Cannot assign to read only property 'exports' of object '#<Object>'
 * 라는 에러를 뿜는다.
 * import, require 둘 다 문제가 발생하며
 * bable.config.js 에 sourceType: 'unambiguous' 를 추가해도 안된다.
 * https://medium.com/@po_thiago/cannot-assign-to-read-only-property-exports-of-object-object-1d908589e135

 * 클라이언트는 import, 서버는 require 로 분리하거나 Object 를 사용하지 말아야 한다.
 * 여기서는 파일을 분리하도록 한다.
 */

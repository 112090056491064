<template>
  <div>
    <div class="mb-3">
      R: 읽기권한, W: 쓰기권한, WB: 대량(batch) 쓰기권한, X: 실행(알림톡 발송 등 취소 불가능한) 권한
    </div>

    <b-row v-for="r in Object.keys(ROLE_INFO)">
      <b-col cols="2" class="mt-1 mb-2">
        {{ ROLE_INFO[r].title }}
        <i class="fa fa-question-circle" v-b-tooltip="ROLE_INFO[r].text"></i>
      </b-col>
      <b-col cols="2" class="mb-1">
        <b-form-checkbox size="sm" v-model="item.role" :value="r" :button-variant="item.role && item.role.includes(r) ? 'info' : 'light'" button>
          {{ r }}
        </b-form-checkbox>
      </b-col>
      <b-col v-if="ROLE_GROUP[r]" class="mb-1">
        <b-form-checkbox v-for="rr in ROLE_GROUP[r].split(',')" size="sm" class="mr-2" v-model="item.role" :value="rr" :key="rr"
                         :button-variant="item.role && ~item.role.indexOf(rr) ? 'success' : 'light'" button>
          {{ rr }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'RoleTab',
  title: '권한설정',
  props: ['item'],
  data() {
    return {
      ROLE_GROUP: this.$C.ROLE_GROUP,
      ROLE_INFO: this.$C.ROLE_INFO,
    }
  },
  async created() {
  },
}
</script>

<style scoped>

</style>
